export const events = {
  reception: {
    name: 'Reception',
    date: 'Friday, November 1, 2019',
    time: {
      hour: '4:30',
      m: 'pm',
    },
    meta: {
      note: 'Dinner and dancing to follow',
    },
  },
};
